body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.af-height-90 {
  max-height: 85vh;
  overflow: hidden;
}

.af-max-width {
  width: auto;
}

.af-bg-dark-transparent {
  background-color: rgba(0, 0, 0, 0.5);
}

.af-position-lg {
  position: relative;
  top: -5rem;
}

.carousel-indicators {
  margin-bottom: 20rem !important;
}

.no_padding {
  padding:0px !important
}

@media (min-width: 650px) {
  .af-position-lg {
    position: relative;
    top: -10rem;
  }

  .af-position-center {
    margin-left: -300px;
  }
}

@media (min-width: 922px) {
  .af-position-lg {
    position: relative;
    top: -20rem;
  }

  .af-position-center {
    margin-left: -300px;
  }
}

@media (min-width: 1000px) {
  .af-position-lg {
    position: relative;
    top: -22rem;
  }
}

@media (min-width: 1100px) {
  .af-position-lg {
    position: relative;
    top: -24rem;
  }

  .carousel-indicators {
    margin-bottom: 10rem !important;
  }
}

@media (min-width: 1150px) {
  .af-position-lg {
    position: relative;
    top: -26rem;
  }

  .carousel-indicators {
    margin-bottom: 15rem !important;
  }

  .service_cards {
    width: "1200px";
  }
}

@media (min-width: 1200px) {
  .content {
    max-width: 1200px;
    margin: 0 auto;
  }

  .af-position-lg {
    position: relative;
    top: -32rem;
  }
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 70px !important;
  height: 65px !important;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 40px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}

@media screen and (max-width: 768px) {
  .hide_on_mobile {
    display: none !important;
  }
}

@font-face {
  font-family: 'antiqua';
  src: local('antiqua'), url(./resources/fonts/BookAntiquaFont.ttf) format('truetype');
}

@font-face {
  font-family: 'bahnschrift';
  src: local('bahnschrift'), url(./resources/fonts/Bahnschrift.TTF) format('truetype');
}

@font-face {
  font-family: 'century';
  src: local('century'), url(./resources/fonts/Century.ttf) format('truetype');
}

@font-face {
  font-family: 'calibri';
  src: local('calibri'), url(./resources/fonts/Calibri.ttf) format('truetype');
}